<template>
    <div class="call-info-modal">
        <div class="call-info-modal-wrapper">
            <div class="call-info-modal-wrapper-title">
                <h2>סניף: {{data.branche}}</h2>
                <div @click="$emit('close')" class="exit-btn">
                    <i class="material-icons">close</i>
                </div>
            </div>
            <div class="call-info-modal-wrapper-content">
                <p>נוצר בתאריך: {{new Date(data.created_at.seconds * 1000).toLocaleString('he')}}</p>
                <br>
                <h2 style="text-decoration: underline;">סוג התקלה</h2>
                <p>{{data.error_type}}</p>
                <br>
                <h2 style="text-decoration: underline;">תיאור התקלה</h2>
                <p>{{data.description}}</p>
                <br>
                <h2 style="text-decoration: underline;">טלפון ליצירת קשר</h2>
                <p>{{data.phone || "לא צויין"}}</p>
                <br>
                <h2 style="text-decoration: underline;">תמונות</h2>
                <p style="color: red;" v-if="!data.images.length">לא קיימות תמונות</p>
                <div v-if="data.images.length" class="images">
                    <template v-for="({image_url}) in data.images" :key="image_url">
                        <div class="image">
                            <img :src="image_url" alt="תמונה תקלה">
                        </div>
                    </template>
                </div>
            </div>
            <div class="call-info-modal-wrapper-footer">
                <div class="status">
                 
                    <el-select style="width: 80%;"
                        v-model="data.status"
                        class="m-2"
                        placeholder="סטטוס"
                    >
                        <el-option
                            v-for="status in statuses_options"
                            :key="status"
                            :value="status"
                        />
                    </el-select>
                </div>
                <div class="submit-btn">
                    <el-button @click="handle_submit" style="width: 80%;" type="success">עידכון סטטוס</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import {alert_confirm} from '../../../../../Methods/Msgs'
export default {
    props:['data'],
    emits:['close','change_status'],
    setup (props,{emit}) {
        
        const statuses_options = ref([
            'פתוח',
            'בטיפול',
            'סגור'
        ])

        const handle_submit = () => {
            alert_confirm(`לשנות סטטוס ל${props.data.status}?`)
            .then(res => {
                if(res.isConfirmed){
                    emit('change_status',props.data)
                }
            })
        }

        return {
            handle_submit,
            statuses_options
        }
    }
}
</script>

<style scoped>
    .call-info-modal{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .call-info-modal-wrapper{
        position: relative;
        width: 100%;
        max-width: 500px;
        height: 80%;
        border-radius: 10px;
        background: #fff;
        display: flex;
        flex-direction: column;
    }
    .call-info-modal-wrapper-title{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 50px;
    }
    .call-info-modal-wrapper-content{
        width: 100%;
        flex: 1;
        padding: 0 5px;
        overflow-y: auto;
    }
    .call-info-modal-wrapper-content > p{
        font-size: 20px;
    } 
    .images{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }
    .images > .image{
   
        width: calc(50% - 5px);
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    .images > .image > img{
        max-width: 100%;
        max-height: 100%;
    }
    .call-info-modal-wrapper-footer{
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5px;
        
    }
    .call-info-modal-wrapper-footer > *{
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
 
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
</style>